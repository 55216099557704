import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AcceptIcon from "../assets/AcceptIcon.png";
import axios from "axios";

const RatingForm = () => {
  const { invoice_id } = useParams();
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log(`invoice_id from useParams: ${invoice_id}`);
    if (!invoice_id) {
      console.error("invoice_id from URL is missing or undefined");
      navigate("/");
    } else {
      console.log(`invoice_id from URL: ${invoice_id}`);
      setFormData((prevState) => ({
        ...prevState,
        invoice_id: invoice_id,
      }));
    }
  }, [invoice_id, navigate]);

  const initializeQuestions = () => {
    let questions = {};
    const totalQuestions = 11;
    for (let i = 1; i <= totalQuestions; i++) {
      questions[`q${i}`] = 0;
    }
    return questions;
  };

  const [formData, setFormData] = useState({
    questions: initializeQuestions(),
    invoice_id: invoice_id || "",
    customer_name: "",
    overall_satisfaction: null,
    customer_email: "",
    customer_phone: "",
    survey_source: "Build-Station",
    survey_type: "Sales",
    additional_comments: "",
  });

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    console.log(
      `Input changed - Name: ${name}, Type: ${type}, Checked: ${checked}, Value: ${value}`
    );

    if (type === "checkbox") {
      setFormData((prevState) => {
        let updatedQuestions = { ...prevState.questions };
        updatedQuestions[value] = checked ? 1 : 0;
        return {
          ...prevState,
          questions: updatedQuestions,
        };
      });
    } else {
      setFormData((prevState) => {
        const newValue = type === "radio" ? parseInt(value) : value;
        console.log(`Setting ${name} to value:`, newValue);
        return {
          ...prevState,
          [name]: newValue,
        };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const finalFormData = new FormData();
    finalFormData.append("invoice_id", formData.invoice_id);
    finalFormData.append("customer_name", formData.customer_name || null);
    finalFormData.append("customer_email", formData.customer_email || null);
    finalFormData.append("customer_phone", formData.customer_phone || null);
    finalFormData.append("survey_source", formData.survey_source);
    finalFormData.append("survey_type", formData.survey_type);
    finalFormData.append(
      "overall_satisfaction",
      formData.overall_satisfaction || null
    );
    finalFormData.append(
      "additional_comments",
      formData.additional_comments || null
    );
    finalFormData.append("questions", JSON.stringify(formData.questions));

    console.log(
      "formData before sending:",
      Array.from(finalFormData.entries())
    );

    try {
      const response = await axios.post(
        "https://mhg-survey.build-station.com/api/survey",
        finalFormData,
        {
          headers: {
            apikey: "FwuEXLGYJT3GoAPAR6SW0VwfgfJsZZx+vM2xwAYSqfI=",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(`Data sent successfully after Question ${currentQuestion}!`);
      console.log("Response from server:", response.data);
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    } catch (error) {
      console.error(
        `Error sending data after Question ${currentQuestion}:`,
        error.message
      );
      console.error("Full error:", error);
      if (error.response) {
        console.error("Server Response:", error.response.data); // Log the server response
      } else {
        console.error("No response received from server.");
      }
    }
  };

  const renderQuestion = (questionNumber) => {
    switch (questionNumber) {
      case 1:
        return <QuestionOne onChange={handleInputChange} t={t} />;
      case 2:
        return (
          <QuestionTwo
            onChange={handleInputChange}
            t={t}
            additionalComments={formData.additional_comments}
          />
        );
      case 3:
        return <SubmitPage t={t} />;
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit} className={i18n.language} id="survey-form">
      {renderQuestion(currentQuestion)}
      <div className="footer">
        {currentQuestion !== 3 && (
          <div id="counter">{`${currentQuestion} / 3`}</div>
        )}
      </div>
    </form>
  );
};

const QuestionOne = ({ onChange, t }) => {
  const [hasSelectedValue, setHasSelectedValue] = useState(false);

  const handleChange = (event) => {
    onChange(event);
    setHasSelectedValue(true);
  };

  return (
    <div className="question" id="question1">
      <p id="q1">{t("q1")}</p>
      <input
        type="radio"
        name="overall_satisfaction"
        id="rate-Q1-5"
        className="hidden"
        value="5"
        onChange={handleChange}
      />
      <label htmlFor="rate-Q1-5" className="far fa-grin-stars" />
      <input
        type="radio"
        name="overall_satisfaction"
        id="rate-Q1-4"
        className="hidden"
        value="4"
        onChange={handleChange}
      />
      <label htmlFor="rate-Q1-4" className="far fa-smile-beam" />
      <input
        type="radio"
        name="overall_satisfaction"
        id="rate-Q1-3"
        className="hidden"
        value="3"
        onChange={handleChange}
      />
      <label htmlFor="rate-Q1-3" className="far fa-meh" />
      <input
        type="radio"
        name="overall_satisfaction"
        id="rate-Q1-2"
        className="hidden"
        value="2"
        onChange={handleChange}
      />
      <label htmlFor="rate-Q1-2" className="far fa-frown" />
      <input
        type="radio"
        name="overall_satisfaction"
        id="rate-Q1-1"
        className="hidden"
        value="1"
        onChange={handleChange}
      />
      <label htmlFor="rate-Q1-1" className="far fa-angry" />
      <div className="footer">
        <span className="text" />
      </div>
      {hasSelectedValue && <button type="submit">{t("submit1")}</button>}
    </div>
  );
};

const QuestionTwo = ({ onChange, t, additionalComments }) => {
  return (
    <div className="question" id="question2">
      <p id="q2">{t("q2")}</p>
      {[
        "quality",
        "value",
        "availability",
        "help",
        "speed",
        "requirements",
        "aftersales",
        "maintenance",
        "delivery",
        "Responsiveness",
        "Trustworthiness",
      ].map((item, index) => (
        <div className="checkbox-container question2" key={item}>
          <input
            type="checkbox"
            name="questions"
            id={item}
            value={`q${index + 1}`}
            onChange={onChange}
          />
          <label htmlFor={item} className="alignment" id={`${item}1`}>
            {t(`${item}1`)}
          </label>
        </div>
      ))}
      <p id="q3">{t("q3")}</p>
      <textarea
        name="additional_comments"
        value={additionalComments}
        onChange={onChange}
      />
      <br />
      <button type="submit">{t("submit2")}</button>
    </div>
  );
};

const SubmitPage = ({ t }) => {
  return (
    <div id="body-form">
      <div id="survey-form">
        <p id="submittxt1">{t("submittxt1")}</p>
        <p id="submittxt2"> {t("submittxt2")} </p>
        <img src={AcceptIcon} alt="Accept Icon" />
        <div>
          <a href="https://www.build-station.com/sa-ar">
            <input type="button" id="button" value={t("button")} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default RatingForm;
