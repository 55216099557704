import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../assets/EnglishStyle.css';
import '../assets/ArabicStyle.css';
import '../assets/SharedStyles.css';

const Navbar = () => {
    const { i18n } = useTranslation(); // Destructure i18n from useTranslation
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    const toggleLanguage = () => {
        if (currentLanguage === 'ar') {

            setCurrentLanguage('en');
            changeLanguage('en');
        } else {
            setCurrentLanguage('ar');
            changeLanguage('ar');
        }
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setCurrentLanguage(lng);
    };

    return (
        <nav className="navbar navbar-light bg-light">
            <div className="lang-switcher">
                <label className="switch">
                    <input type="checkbox" onChange={toggleLanguage} checked={currentLanguage === 'en'} />
                    <span className="slider round"></span>
                    <span className="lang-text">{currentLanguage === 'en' ? 'عربي' : 'English'}</span>
                </label>
            </div>
        </nav>
    );
}

export default Navbar;
