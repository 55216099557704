import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';

import Navbar from './components/Navbar';
import Header from './components/Header';
import RatingForm from './components/RatingForm';
import i18n from './i18n'; // Assuming you've set up i18n in a file named 'i18n.js' as previously described

function App() {
  const { t } = useTranslation();

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className="App">
          <Navbar />
          <Header />
          <Routes>
            <Route path="/:invoice_id" element={<RatingForm />} />
            <Route path="/" element={<RatingForm />} />
          </Routes>
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;