import React from 'react';
import BuildStationLogo from '../assets/BuildStationLogo.png';

const Header = () => {
    return (
        <header>
            <a href="https://www.build-station.com/sa-ar"> <img src={BuildStationLogo} alt="BuildStation Logo" /></a>
        </header>
    );
}

export default Header;
